<template>
  <div class="history">
    <div>
      <el-row class="topRow">
        <el-col :span="8">
        <el-date-picker
          v-model="timeRange"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start time"
          end-placeholder="End Time"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          picker-options="pickerOptions"
          @change="selectTimeFunc"
        >
        </el-date-picker>
        </el-col>
        <el-col :span="2">
          <label>{{$t('history.acc')}}</label>
          <el-switch v-model="accOn"></el-switch>
        </el-col>
        <el-col :span="6" >
        <el-button class="play" type="primary" :disabled="playDisable" @click="playTrace">{{$t('history.play')}}</el-button>
        <el-button type="success" :disabled="pauseDisable" @click="pauseTrace">{{pauseText}}</el-button>
        <el-button type="info" :disabled="stopDisable" @click="stopTrace">{{$t('history.stop')}}</el-button>
        </el-col>
        <el-col :span="6" >
          <el-col :span="2">
          <span class="slow" >{{$t('history.slow')}}</span>
          </el-col>
          <el-col :span="4">
          <el-slider v-model="sliderValue" @change="changeSlider"></el-slider>
          </el-col>
          <el-col :span="2">
          <span class="fast">{{$t('history.fast')}}</span>
          </el-col>
        </el-col>
      </el-row>
    </div>
    <GmapMap
      class="mapClass"
      ref="mapRef"
      map-type-id="terrain"
      v-if="currentMap === 'google'"
      :center="{lat:25.037798, lng:121.565170}"
      :zoom="13"
      style="width: 100%; height: 95%;"
      :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUI: false
        }"></GmapMap>
    <baidu-map v-if="currentMap === 'baidu'" class="baidu-map" :center="center" :zoom="13" @ready="handleBaidu"
               :scroll-wheel-zoom='true'>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"/>
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"/>
    </baidu-map>
  </div>
</template>

<script>
import { getGpsDeviceByImei } from '@/api/gpsDevice'
import { checkLogin } from '@/api/globalProcessErr'
import { getDeviceTrackLog } from '@/api/gpsDeviceTraceLog'
import { getDateFullStr } from '@/api/dateFunc'

import { initMap, makePath, makeMarker, moveToCenter, makeTrackMarker } from '@/api/map'
import { deviceStatusFormat, getObjString } from '@/api/util'
import { convertTrackToDevice } from '../../api/map'

export default {
  name: 'tracking',
  data () {
    return {
      infoWindows: [],
      deviceImei: '',
      center: {
        lat: 22.5711440000,
        lng: 113.8687300000
      },
      playDisable: false,
      pauseDisable: true,
      stopDisable: true,
      pause: false,
      pauseText: null,
      device: undefined,
      mapInstance: undefined,
      playIndex: 0,
      sliderValue: 50,
      rows: 1000,
      maxRows: 1000,
      intervalId: undefined,
      intervalTime: 1000,
      nextDevice: undefined,
      mapDriverPath: undefined,
      nextId: 0,
      timeRange: [
        new Date((new Date()) - 60 * 60 * 1000),
        new Date()
      ],
      accOn: false,
      devicePath: [],
      deviceMark: {
        deviceImei: '',
        marker: undefined,
        infoWindow: undefined
      },
      minTime: undefined,
      maxTime: undefined,
      currentMap: 'baidu',
      pickerOptions: {
        onPick (time) {
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            let timeRange = 24 * 60 * 60 * 1000 // 6天
            this.minTime = time.minDate.getTime() - timeRange // 最小时间
            this.maxTime = time.minDate.getTime() + timeRange // 最大时间
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            this.minTime = this.maxTime = null
          }
        },
        disabledDate (time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (this.minTime && this.maxTime) {
            return time.getTime() < this.minTime || time.getTime() > this.maxTime
          }
        }
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.deviceImei = window.sessionStorage.getItem('historicalTrackDeviceImei') || '8666719959'
    this.checkLang()
    this.pauseText = this.$t('history.pause')
  },
  mounted () {
    const _this = this
    setTimeout(function () {
      _this.initPage()
    }, 400)
  },
  methods: {
    initPage () {
      const _this = this
      const startTime = sessionStorage.getItem('startTime')
      const endTime = sessionStorage.getItem('endTime')
      if (startTime && endTime) {
        _this.timeRange[0] = new Date(parseInt(startTime))
        _this.timeRange[1] = new Date(parseInt(endTime))
        _this.getTraceLogOnce()
      } else {
        _this.getGpsDevice().then(() => {
          _this.initMapPage()
          moveToCenter(_this.mapInstance, _this.center)
          makeMarker(_this.mapInstance, _this.device)
        })
      }
    },
    checkLang () {
      if (this.$i18n.locale === 'zh-CN') {
        this.currentMap = 'baidu'
      } else {
        this.currentMap = 'google'
      }
    },
    handleBaidu ({ BMap, map }) {
      this.mapInstance = {
        mapType: 'baidu',
        map: map,
        bMap: BMap,
        // map: window.$BAIDU$._instances.TANGRAM__1,
        // bMap: window.BMap,
        markList: [],
        currentOpenInfoWindow: undefined,
        contentFunc: this.getTrackContent,
        currentPoly: undefined,
        polyList: [],
        haveBtn: false
      }
    },
    initMapPage () {
      if (this.currentMap === 'google') {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'google',
            map: this.$refs.mapRef.$mapObject,
            mapRef: this.$refs.mapRef,
            googleMaps: window.google.maps,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.getTrackContent,
            currentPoly: undefined,
            polyList: [],
            haveBtn: false
          }
        }
      } else {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'baidu',
            // map: map,
            // bMap: BMap,
            map: window.$BAIDU$._instances.TANGRAM__1,
            bMap: window.BMap,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.getTrackContent,
            currentPoly: undefined,
            polyList: [],
            haveBtn: false
          }
        }
      }
      initMap(this.mapInstance, this.center, 13)
    },
    motionStatusFormatter (row, column) {
      if (row.lastMotionStatus === 1) {
        return 'motion'
      } else if (row.lastMotionStatus === 0) {
        return 'static'
      } else {
        return ''
      }
    },
    getGpsDevice () {
      const self = this
      return new Promise(resolve => {
        getGpsDeviceByImei(self.deviceImei).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          self.device = res.data.data
          self.center = {
            lat: self.device.lastLatitude,
            lng: self.device.lastLongitude
          }
          resolve('')
        }).catch(err => {
          return self.$message.error(err.message)
        })
      })
    },
    playTrace () {
      this.playDisable = true
      this.pauseDisable = false
      this.stopDisable = false
      // this.devicePath = []
      this.rows = this.maxRows
      const self = this
      this.nextId = 0
      this.playIndex = 0
      this.pause = false
      // this.getTraceLogOnce()
      setTimeout(function () {
        setTimeout(function () {
          self.interval()
        }, 100)
      }, 1000)
    },
    pauseTrace () {
      if (this.pauseText === this.$t('history.pause')) {
        this.pauseText = this.$t('history.continue')
        // pause
        this.pause = true
        clearInterval(this.intervalId)
      } else {
        this.pauseText = this.$t('history.pause')
        // continue
        this.pause = false
        this.interval()
      }
    },
    changeSlider () {
      clearInterval(this.intervalId)
      this.interval()
    },
    stopTrace () {
      this.playDisable = false
      this.stopDisable = true
      this.pauseDisable = true
      this.pause = false
      this.playIndex = 0
      // this.devicePath = []
      this.pauseText = this.$t('history.pause')
      clearInterval(this.intervalId)
      // cleanCurrentPath(this.mapInstance)
      // cleanMarks(this.mapInstance)
      // makeMarker(this.mapInstance, this.device)
    },
    interval () {
      if (this.pause) {
        return
      }
      if (this.stopDisable) {
        clearInterval(this.intervalId)
      }
      const _this = this
      this.intervalId = undefined
      this.intervalTime = 500 + (100 - this.sliderValue) * 10
      this.intervalId = setInterval(function () {
        const device = _this.getNextDevice()
        if (device !== undefined) {
          const point = new _this.mapInstance.bMap.Point(device.lastLongitude, device.lastLatitude)
          _this.mapInstance.map.openInfoWindow(_this.infoWindows[_this.playIndex], point)
          moveToCenter(_this.mapInstance, point)
        }
      }, _this.intervalTime)
    },
    getNextDevice () {
      if (this.playIndex >= this.devicePath.length) {
        this.stopTrace()
        return undefined
      }
      const device = {
        deviceImei: this.devicePath[this.playIndex].deviceImei,
        lastLongitude: this.devicePath[this.playIndex].lng,
        lastLatitude: this.devicePath[this.playIndex].lat,
        heading: this.devicePath[this.playIndex].heading
      }
      this.playIndex = this.playIndex + 1
      return device
    },
    getTraceLogOnce () {
      const self = this
      return new Promise(resolve => {
        if (self.rows < self.maxRows) {
          makePath(self.mapInstance, self.devicePath)
          return 0
        }
        const req = {
          requestRows: self.maxRows,
          nextId: self.nextId,
          deviceImei: self.deviceImei,
          startTime: getDateFullStr(self.timeRange[0]),
          endTime: getDateFullStr(self.timeRange[1]),
          accOn: self.accOn ? 1 : 0
        }
        getDeviceTrackLog(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          if (res.data.data.list.length > 0) {
            for (let i = 0; i < res.data.data.list.length; i++) {
              const track = res.data.data.list[i]
              if (i === 0) {
                makeTrackMarker(self.mapInstance, track, 'start')
                moveToCenter(self.mapInstance, { lng: track.lng, lat: track.lat })
              } else if (i === res.data.data.list.length - 1) {
                makeTrackMarker(self.mapInstance, track, 'end')
              } else {
                if (track.supplement === 1) {
                  makeTrackMarker(self.mapInstance, track, 'supplement')
                } else {
                  makeTrackMarker(self.mapInstance, track, 'subordinate')
                }
              }
              let infoWindow = new self.mapInstance.bMap.InfoWindow((self.getTrackContent(convertTrackToDevice(track)))())
              self.infoWindows.push(infoWindow)
              // marker.addEventListener('click', (e) => {
              //   moveToCenter(self.mapInstance, e.target.point)
              //   self.mapInstance.map.openInfoWindow(infoWindow, e.target.point)
              // })
              self.devicePath.push(track)
            }
            self.nextId = res.data.data.maxLogId
            self.rows = res.data.data.list.length
            self.getTraceLogOnce()
          } else {
            self.rows = 0
            makePath(self.mapInstance, self.devicePath)
            return 0
          }
        }).catch(err => {
          return self.$message.error(err.message)
        })
      })
    },
    openInfoWindowByDeviceImei () {
      this.deviceMark.infoWindow.open({
        anchor: this.deviceMark.marker,
        map: this.getMapInstance(),
        shouldFocus: true
      })
    },
    selectTimeFunc () {
      this.playIndex = 0
      this.devicePath = []
      this.infoWindows = []
      this.mapInstance.map.clearOverlays()
      this.getTraceLogOnce()
    },
    getTrackContent (data) {
      const self = this
      if (data.deviceType === 1) {
        return function () {
          // const motionStatus = self.motionStatusFormatter(data)
          let content = '<style> .deviceInfo { font-size: 12px; margin: 0; padding: 0;} ul li { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; } ul li div { width: 160px; } .centerDiv{ width: 100%; display: flex; justify-content: space-around; margin-top: 4px; } .centerDiv button { font-size: 12px; width: 60px; border-radius: 10px;} </style>'
          content += '<ul class="deviceInfo">'
          let item = 'engine'
          content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + data.deviceImei + '</span> </div>'
          content += '<div> <b>' + self.$t('device.deviceName') + ':</b> <span>' + data.deviceName + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + getObjString(data.lat) + '</span> </div>'
          content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + getObjString(data.lng) + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.accStatus') + ':</b> <span>' + deviceStatusFormat(data, item) + '</span> </div>'
          content += '<div> <b>' + self.$t('device.lastLocationTime') + ':</b> <span>' + getObjString(data.gpsTime) + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.lastSpeed') + ':</b> <span>' + getObjString(data.lastSpeed) + '</span> </div>'
          content += '<div> <b>' + self.$t('device.heading') + ':</b> <span>' + getObjString(data.heading) + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.odometer') + ':</b> <span>' + getObjString(data.odometer) + '</span> </div>'
          content += '<div> <b>' + self.$t('device.fuel') + ':</b> <span>' + getObjString(data.deviceVol) + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.temperature') + ':</b> <span></span> </div>'
          content += '<div> <b>' + self.$t('device.address') + ':</b> <span></span> </div> </li>'
          content += '</ul>'
          return content
        }
      } else {
        return function () {
          let speed = 0
          if (data.odometer && data.duration && data.duration !== 0) {
            speed = data.odometer / data.duration
          }
          let averageSpeed = 0
          if (data.accumulateOdometer && data.accumulateDuration && data.accumulateDuration !== 0) {
            averageSpeed = data.accumulateOdometer / data.accumulateDuration
          }
          let content = '<style> .deviceInfo { font-size: 10px; margin: 0; padding: 0;} ul li { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; } ul li div { width: 200px; } .centerDiv{ width: 100%; display: flex; justify-content: space-around; margin-top: 4px; } .centerDiv button { font-size: 12px; width: 60px; border-radius: 10px;} </style>'
          content += '<ul class="deviceInfo">'
          content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + data.deviceImei + '</span> </div>'
          content += '<div> <b>' + self.$t('device.nickname') + ':</b> <span>' + data.deviceName + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.lastDeviceVol') + ':</b> <span>' + (data.lastDeviceVol || 0) + '%' + '</span> </div>'
          content += '<div> <b>' + self.$t('device.version') + ':</b> <span>' + (data.version || '') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.positioningTime') + ':</b> <span>' + data.lastGpsTime + '</span> </div>'
          content += '<div> <b>' + self.$t('device.uploadTime') + ':</b> <span>' + data.lastLocationTime + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + data.lastLatitude + '</span> </div>'
          content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + data.lastLongitude + '</span> </div> </li>'
          // content += '<li> <div> <b>' + self.$t('device.weather') + ':</b> <span>' + device.weather + '</span> </div>'
          // content += '<div> <b>' + self.$t('device.lastPositionDesc') + ':</b> <span>' + device.lastPositionDesc + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.distanceFromPreviousPoint') + ':</b> <span>' + (data.odometer || 0) + self.$t('unit.meter') + '</span> </div>'
          content += '<div> <b>' + self.$t('device.timeFromPreviousPoint') + ':</b> <span>' + (data.duration || 0) + self.$t('unit.second') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('unit.speedPerMinute') + ':</b> <span>' + (speed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
          content += '<div> <b>' + self.$t('unit.speedPerHour') + ':</b> <span>' + (speed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('unit.averageSpeedPerMinute') + ':</b> <span>' + (averageSpeed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
          content += '<div> <b>' + self.$t('unit.averageSpeedPerHour') + ':</b> <span>' + (averageSpeed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.altitude') + ':</b> <span>' + (data.altitude || 0) + self.$t('unit.meter') + '</span> </div>'
          content += '<div> <b>' + self.$t('device.locationPoints') + ':</b> <span>' + (data.pointIndex || '') + '</span> </div> </li>'
          return content
        }
      }
    }
  }
}
</script>

<style scoped>
.history {
  width: calc(100% - 10px);
  height: 100%;
}

.topRow {
  height: 40px;
  margin: 5px 0;
  padding: 0 5px;
  line-height: 40px;
}

.mapClass {
  top: 20px;
}

.baidu-map {
  width: 100%;
  height: 95%;
  margin-top: 5px;
  top: 45px;
}

.play {
  margin-left: 10px;
}

.slow {
  align-content: center;
  vertical-align: middle;
  margin-top: 10px!important;
}

.deviceInfo {
  color: red;
}

</style>
